import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo_web.png";

function FooterHomeOne({ className }) {
    return (
        <>
            <section className={`appie-footer-area ${className || ""}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget">
                                <div className="logo">
                                    <a href="#">
                                        <img src={logo} alt="" width="170" />
                                    </a>
                                </div>
                                <p>
                                    Transforma tus días y eleva tu mentalidad
                                    con Afirmaciones.
                                </p>
                                <a href="/privacidad">
                                    Política de privacidad{" "}
                                    <i className="fal fa-arrow-right" />
                                </a>
                                {/* <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-pinterest-p" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        {/* <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Company</h4>
                                <ul>
                                    <li>
                                        <Link to="/about-us">Política de privacidad</Link>
                                    </li>
                                    <li>
                                        <Link to="/Service">Service</Link>
                                    </li>
                                    <li>
                                        <a href="#">Case Studies</a>
                                    </li>
                                    <li>
                                        <Link to="/news">Blog</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                        {/* <div className="col-lg-3 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Support</h4>
                                <ul>
                                    <li>
                                        <Link to="/about-us">Community</Link>
                                    </li>
                                    <li>
                                        <a href="#">Resources</a>
                                    </li>
                                    <li>
                                        <a href="#">Faqs</a>
                                    </li>
                                    <li>
                                        <a href="#">Privacy Policy</a>
                                    </li>
                                    <li>
                                        <a href="#">Careers</a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                        {/* <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Contacto</h4>
                                <ul>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-envelope" />{" "}
                                            hey@42designagency.com
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-phone" />{" "}
                                            +(642) 342 762 44
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-map-marker-alt" />{" "}
                                            442 Belle Terre St Floor 7, San
                                            Francisco, AV 4206
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <a href="https://apps.apple.com/us/app/insp%C3%ADrame/id6447495002">
                                                <i className="fab fa-apple" />{" "}
                                                Descargar para iOS
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a className="item-2" href="#">
                                                <i className="fab fa-google-play" />{" "}
                                                Descargar para Android
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="copyright-text">
                                    {/* Copyright text with current year */}
                                    <p>
                                        © {new Date().getFullYear()} Inspírame.
                                        Todos los derechos reservados.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeOne;
